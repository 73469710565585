import React from 'react'

import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBTypography, MDBInput, MDBTextArea } from 'mdb-react-ui-kit';
import Axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { useState } from 'react';
import { SubjectSharp } from '@mui/icons-material';

function Contact() {
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [number, setNumber] = useState("")
    const [message, setMessage] = useState("")
    
    const [returnData, setReturnData] = useState([])

    const sendMessage = () => {
        Axios.post('https://backend.cjncode.co.za/sendMail',
            {
                name: name,
                surname: surname,
                email: email,
                number: number,
                message: message,
                
            }
        ).then((response) => {
            setReturnData(response.data)
            console.log(response.data)
            window.location.reload()
        })
    }
      return(
        <div className="gradient-custom-2" style={{ backgroundColor: '#000000', marginTop: 50 }}>
       
     <MDBContainer className="py-5 h-100" style={{boxShadow: '1px 2px 9px #F4AAB9'}}>
       <MDBRow className="justify-content-center align-items-center h-100">
         <MDBCol lg="9" xl="7" >
           <MDBCard >
             
             <ReactPlayer url="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/TGT.m4v" muted playing={true} loop width={'100%'} height={'50%'} style={{zIndex: 5}}/>
               <div className="ms-4 mt-5 d-flex flex-column" style={{ width: '150px' }}>
                 {/* <MDBCardImage src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"
                   alt="Generic placeholder image" className="mt-4 mb-2 img-thumbnail" fluid style={{ width: '150px', zIndex: '1' }} /> */}
                 {/* <MDBBtn outline color="dark" style={{height: '36px', overflow: 'visible'}}>
                   Edit profile
                 </MDBBtn> */}
               </div>
               
               {/* <div className="ms-3" style={{ marginTop: '130px' }}>
                 <MDBTypography tag="h5">TONGAAT</MDBTypography>
                 <MDBCardText>Campus</MDBCardText>
               </div> */}
            
             <div className="p-4 text-black" style={{ backgroundColor: 'white' , textAlign: 'center'}}>
               <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                 <div className='px-3'>
                 <Link
                                           style={{
                                             textDecoration: 'none',
                                             color: 'inherit'
                                           }}
                                           to="https://www.google.com/maps/place/NCF+North/@-29.5741463,31.1022931,17z/data=!4m15!1m8!3m7!1s0x1ef710c9a6b15299:0x7861c3af5b19082b!2s6+Bhoola+Rd,+Trurolands,+oThongathi,+4399!3b1!8m2!3d-29.574151!4d31.104868!16s%2Fg%2F11c5njwx0m!3m5!1s0x1ef71ae04ba5059b:0x23a988eb5b1fafee!8m2!3d-29.574151!4d31.104868!16s%2Fg%2F11b6y_vq8q?entry=ttu"
                                           target="_blank"
                                           rel="noreferrer"
                     >
                   <LocationOnIcon />
                   <MDBCardText className="small text-muted mb-0">6 Bhoola Road</MDBCardText>
                   <MDBCardText className="small text-muted mb-0"></MDBCardText>
                   </Link>
                 </div>
                 <div className="px-3">
                 
                   <AccessTimeIcon />
                   <MDBCardText className="small text-muted mb-0">Mon - Fri</MDBCardText>
                   <MDBCardText className="small text-muted mb-0">8am - 4pm</MDBCardText>
                 </div>
                 <div className="px-3">
                 <Link
                                           style={{
                                             textDecoration: 'none',
                                             color: 'inherit'
                                           }}
                                           to="tel:+2729443330"
                                           >
                 <CallIcon />
                   <MDBCardText className="small text-muted mb-0">0329443330</MDBCardText>
                   </Link>
                 </div>
               </div>
             </div>
             <MDBCardBody className="text-black p-4">
               <div className="mb-5">
               <div style={{display: 'flex', flexDirection: 'row'}}>
                 <p className="lead fw-normal mb-1" style={{fontWeight: 'bold'}}>Drop Us A Message!</p>
                 
                 </div>
                 <MDBCol col='4' md='6'>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <MDBInput wrapperClass='mb-4' label='Name'  type='text' size="lg" style={{width: '90%'}} onChange={(e) => {
                        setName(e.target.value)
                    }}/>
                    <MDBInput wrapperClass='mb-4' label='Surname'  required={true} type='text' size="lg" style={{width: '100%'}} onChange={(e) =>{
                        setSurname(e.target.value)
                    }}/>
                    </div>
                    <MDBInput wrapperClass='mb-4' label='Email'  type='text' size="lg" onChange={(e) => {
                        setEmail(e.target.value)
                    }}/>
                    <MDBInput wrapperClass='mb-4' label='Number'  type='text' size="lg" onChange={(e) => {
                        setNumber(e.target.value)
                    }}/>
                    <MDBTextArea wrapperClass='mb-4' label='Your Message'  type='text' size="lg" onChange={(e) => {
                        setMessage(e.target.value)
                    }}/>
                    <MDBBtn style={{backgroundColor: 'black'}} onClick={() => sendMessage()}>SUBMIT</MDBBtn>
                </MDBCol>
                 <div className="p-4" style={{ backgroundColor: 'white' }}>
                   <MDBCardText className="font-italic mb-1" style={{color: 'black	'}}></MDBCardText>
                 </div>
               </div>
               <div className="mb-5">
               <div style={{display: 'flex', flexDirection: 'row'}}>
                 <p className="lead fw-normal mb-1" style={{fontWeight: 'bold'}}>Reach Out Elsewhere</p>
                 
                 </div>
                 <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                   <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                     <Link style={{
                         textDecoration: 'none',
                         color: 'inherit'
                       }}
                       to="mailto: info@ncfnorth.co.za"
                       target="_blank"
                       rel="noreferrer">
                     <AttachEmailIcon fontSize='large'/>
                     </Link>
                     
                     <Link
                                           style={{
                                             textDecoration: 'none',
                                             color: 'inherit'
                                           }}
                                           to="https://www.ncfnorth.co.za"
                                           target="_blank"
                                           rel="noreferrer"
                     >
                     <LanguageIcon fontSize='large'/>
                     </Link>
                   </div>
                 </div>
               </div>
               <div className="d-flex justify-content-between align-items-center mb-4">
          
               </div>
               <MDBRow>
               
               </MDBRow>
             </MDBCardBody>
           </MDBCard>
         </MDBCol>
       </MDBRow>
       </MDBContainer>
       
     </div>
      )
    }
 


export default Contact
