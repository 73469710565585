import { MDBContainer, MDBInput, MDBIcon, MDBBtn, MDBCardText } from 'mdb-react-ui-kit';
import Axios from 'axios'
import React, { useState} from 'react'

import { useLocation } from 'react-router-dom'


function ReadBible() {
    const location = useLocation();
    const [search, setSearch] = useState("");
    const bibleVersion = location.state
    const [content, setContent] = useState([])

    const handleSearch = () => {
        Axios.post(`https://api.biblia.com/v1/bible/content/${bibleVersion}.txt.txt?passage=${search}&callback=myCallbackFunction&key=fd37d8f28e95d3be8cb4fbc37e15e18e`).then((response) => {
            setContent(response.data);
        })
    }
  return (
   <MDBContainer fluid className="p-3" style={{marginTop: "25%"}}>
         <MDBInput wrapperClass='mb-4' label='' placeholder='Search Bible Verse Here'  type='text' size="lg" style={{borderTopLeftRadius: 25, borderBottomLeftRadius: 25, borderTopRightRadius: 25, borderBottomRightRadius: 25}} onChange={(e) => {
            setSearch(e.target.value)
          }}/>
          <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: 'grey'}} onClick={() => handleSearch()}>
              SEARCH
          </MDBBtn>
          <MDBCardText style={{textAlign: 'center', fontSize: 20}}>"{content}"</MDBCardText>
   </MDBContainer>
  )
}

export default ReadBible