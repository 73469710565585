import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import FirebaseApp
 from './Firebase';
import AppStack from './Stacks/AppStack';
import Notification from './components/Notification';

import { requestForToken } from './Firebase';


function App() {



  return (
    <div className="App">
          <AppStack/>
          <Notification/>
    </div>


  );
}

export default App;
