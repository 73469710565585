import React, { useState, useEffect, } from 'react';
import Axios from 'axios';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";
import { Alert } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LoadingSpinner from '../components/LoadingSpinner';

function Login() {
  const [passwordType, setPasswordType] = useState("password");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loginStatus, setLoginStatus] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(false);
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false))
    const navigate = useNavigate();
    const [user, setUser] = useState()


    
    
    
    // inside the jsx being returned:
 

    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    const LoginAction = () =>{
        Axios.post("https://backend.cjncode.co.za/login", {
            recEmail: email,
            recPassword: password
           
        },  setIsLoading(true)).then((response) => {
            if (response.data.message) {
                setLoginStatus(response.data.message)
                setAlert(true)
                setIsLoading(false)
                
            } else {
              setIsLoading(false);
                setLoginStatus(response.data[0].email)
                setauthenticated(true)
                setUser(response.data)
                setauthenticated(true)
                localStorage.setItem('user', response.data[0].email)
                localStorage.setItem('isLoggedIn', true)
                navigate("/home");
                
            }
        })
    }

    useEffect(() => {
      if(localStorage.getItem('isLoggedIn')) {
        navigate("/home");
      }
    })

    const RegisterAction = () => {
      navigate("/register")
    }



  return (

    <MDBContainer fluid className="p-3 my-5">
      
       
      <MDBRow>

        <MDBCol col='10' md='6'>
          <img src="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/BlackLogo.png" className="img-fluid" alt="Phone image" />
        </MDBCol>
   
        <MDBCol col='4' md='6'>
        {isLoading ? <LoadingSpinner /> : <></>}
        { alert? <Alert severity="error">{loginStatus}</Alert> : <></>}
          <MDBInput wrapperClass='mb-4' label='Email address'  type='email' size="lg" onChange={(e) => {
            setEmail(e.target.value)
          }}/>
          <MDBInput wrapperClass='mb-4' label='Password'  type={passwordType} size="lg" onChange={(e) => {
            setPassword(e.target.value)
          }}/>
          <div style={{display: 'flex', flexDirection: 'row'}}>
          <div className="d-flex justify-content-between mx-4 mb-4">
            <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Show Password' onClick={togglePassword}/>
            
          </div>
          <div className="d-flex justify-content-between mx-4 mb-4">
            <h6 style={{textDecoration: 'underline'}} onClick={() => navigate("/resetPassword")}>Forgot Password?</h6>
          </div>
          </div>
          <MDBBtn className="mb-4 w-100" size="lg" onClick={LoginAction} style={{backgroundColor: 'black'}} ><VpnKeyIcon style={{marginRight: 10, marginBottom: 8}}/>SIGN IN</MDBBtn>

          <div className="divider d-flex align-items-center my-4 text-center">
            <p className="text-center fw-bold mx-3 mb-0 margin-right-50"></p>
          </div>

          <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: 'grey'}} onClick={RegisterAction}>
            <MDBIcon fab icon="facebook-f" className="mx-2" />
              <AppRegistrationIcon style={{marginRight: 10, marginBottom: 8}}/>REGISTER
          </MDBBtn>
        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default Login;