import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

  import './tab.css'
  import HomeIcon from '@mui/icons-material/Home';
  import { useNavigate } from 'react-router-dom';
 



export default function TabNav() {
    const [value, setValue] = React.useState(0);
    const navigation = useNavigate()
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

  return (
    
    <Box sx={{ bgcolor: 'white',  width: '100%', alignSelf: 'center', marginTop: 7, elevation: 7}}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="scrollable force tabs example"
        elevation={5}
      >
        <Tab icon={<HomeIcon/>} style={{fontSize: 18, fontWeight: 'bold', marginTop: 15}} onClick={() => navigation('/home')}/>
        <Tab label="Tongaat" style={{fontSize: 18, fontWeight: 'bold', marginTop: 15}} onClick={() => navigation('/tongaat')}/>
        <Tab label="Verulam" style={{fontSize: 18, fontWeight: 'bold', marginTop: 15}} onClick={() => navigation('/verulam')}/>
        <Tab label="Stanger" style={{fontSize: 18, fontWeight: 'bold', marginTop: 15}} onClick={() => navigation('/stanger')}/>
        <Tab label="Mandini" style={{fontSize: 18, fontWeight: 'bold', marginTop: 15}} onClick={() => navigation('/mandini')}/>
        <Tab label="Johannesburg" style={{fontSize: 18, fontWeight: 'bold', marginTop: 15}} onClick={() => navigation('./johannesburg')}/>
      </Tabs>
    </Box>

   
  )
}