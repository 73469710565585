import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {
  MDBContainer,
} from 'mdb-react-ui-kit';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Axios from 'axios'


function Bible() {
  const navigate = useNavigate();

  const [bibles, setBibles] = useState([]);

  useEffect(() => {
    Axios.post("https://api.biblia.com/v1/bible/find.txt?key=fd37d8f28e95d3be8cb4fbc37e15e18e").then((response) => {
      setBibles(response.data.bibles);
      console.log(response.data)
    })
  },[])

  return (
    <MDBContainer fluid className="p-3" style={{marginTop: '18%'}} >
    <ImageList sx={{ width: '100%', height: "100%" }}>
      <ImageListItem key="Subheader" cols={2}>
        
      </ImageListItem>
      {bibles.map((item, key) => (
        <ImageListItem key={key}>
          <img
            src={item.imageUrl}
            alt={item.title}
            
            onClick={()=>navigate("/readBible", {state: item.bible})}
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
          />
        </ImageListItem>
      ))}
    </ImageList>
    </MDBContainer>
  );
}

export default Bible