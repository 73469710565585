import React, {useState, useEffect, TouchableOpacity} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
} from 'mdb-react-ui-kit';
import Axios from 'axios';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

export default function VideoPage() {
  const [playlist, setPlaylist] = useState([]);
  const navigate = useNavigate();
 

  useEffect(()=>{
    Axios.get("https://backend.cjncode.co.za/getPlaylist").then((response)=> {
      setPlaylist(response.data);
      console.log(response.data)
    })
  },[])
  return (
    <MDBContainer fluid className="p-3 my-5" >
      {playlist.map((val, key) =>(
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} style={{marginTop: 5}} key={key}>
      
      
      <ListItem alignItems="flex-start"  >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={val.playlistImage} />
        </ListItemAvatar>
        <ListItemText
          style={{fontWeight: 'bold'}}
          primary={val.playlistTitle}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {val.playlistDescription}
              </Typography>
              
            </React.Fragment>
          }
        />
        <ArrowForwardIosIcon style={{marginTop: 20, marginLeft:30}} onClick={()=>navigate("/vplayer", {state: val.playlistTitle})}/>
      </ListItem>
      
     
        <Divider variant="inset" component="li" />
      
    </List>
     ))}
    </MDBContainer>
  );
}