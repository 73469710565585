import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {
  MDBContainer,
} from 'mdb-react-ui-kit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Axios from 'axios'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoIcon from '@mui/icons-material/Info';
import { Skeleton } from '@mui/material';

function Tongaat() {
  const [stEvent, setStEvent] = useState([]);
  const [spEvent, setSpEvent] = useState([])
  const [dataMessage, setDataMessage] = useState("")
  const [alert, setAlert] = useState(false)
  const [eventDataLoad, setEventDataLoad] = useState(true);
  const [spEventDataLoad, setSpEventDataLoad] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.post("https://backend.cjncode.co.za/getSpecialEventsTongaat").then((response) => {
      setSpEvent(response.data)
      setEventDataLoad(false);
    })
  }, [])

  useEffect(() => {
    Axios.post("https://backend.cjncode.co.za/getStaticEvents").then((response) => {
      setStEvent(response.data)
      setSpEventDataLoad(false);
    })
  },[])

const  ReturnSpEventData = () => {
  return(
    <>
    {spEvent.map((val, key) => (
      <Card sx={{ width:'100%', alignSelf: 'center', marginBottom: 5}} >
          <CardActionArea>
            <CardMedia
              component="img"
              height="190"
              image={val.eventArt}
              alt="green iguana"
            />
            <CardContent style={{textAlign: 'center'}}>
              <Typography gutterBottom variant="h5" component="div" style={{fontWeight: 'bold'}}>
                {val.eventTitle}
              </Typography>
              <div style={{marginTop: '5%'}}>
                <AccessTimeFilledIcon/>
              <Typography variant="body2" color="text.secondary" style={{fontSize: 20}}>
                  {val.eventTime}
              </Typography>
              </div>
              <div style={{marginTop: '5%'}}>
                <CalendarMonthIcon/>
              <Typography variant="body2" color="text.secondary" style={{fontSize: 20}}>
                  {val.eventDate}
              </Typography>
              </div>
              <div  style={{marginTop: '5%'}}>
                <InfoIcon/>
              <Typography variant="body2" color="text.secondary" style={{fontSize: 20}}>
                  {val.eventDescription}
              </Typography>
              </div>
            </CardContent>
          </CardActionArea>
          </Card>
          ))}
    </>
  )
}

const ReturnEventData = () => {
  return (
    <>
{stEvent.map((val, key) => (
    <Card sx={{ width:'100%', alignSelf: 'center', marginBottom: 5}} onClick={() => navigate('/events', {state: val.eventTitle})}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="140"
            image={val.eventArt}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {val.eventTitle}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                NCF North Tongaat Event
            </Typography>
          </CardContent>
        </CardActionArea>
        </Card>
        ))}
    </>
  )
}
  return (
   
    <MDBContainer style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
    <MDBContainer fluid className="p-3">
      <div style={{
        marginTop: '5%', 
        width: '100%', 
        height: 100,
        backgroundColor: 'black', 
        borderTopLeftRadius: 50, 
        borderBottomLeftRadius: 50,
        padding: '5%',
        color: 'white',
        borderTopRightRadius: 50, 
        borderBottomRightRadius: 50,
        textAlign: 'center',
        boxShadow: '1px 2px 9px #F4AAB9'
      }}
      onClick={() => navigate('/details', {state: 'Tongaat'})}  
    >
        <p style={{fontSize: 20}}>Tongaat Campus</p>
        <p>Click to view details</p>
      </div>
      
     
    </MDBContainer>
    <Typography gutterBottom variant="h5" component="div" style={{alignSelf: 'center', marginTop: '10%', fontWeight: 'bold', }}>
              UPCOMING SPECIAL EVENTS
              
      </Typography>
      {spEventDataLoad? (<Skeleton variant="card" width={"100%"} height={300} />) : (<ReturnSpEventData/>) }
    
    <Typography gutterBottom variant="h5" component="div" style={{alignSelf: 'center', marginTop: '10%', fontWeight: 'bold', }}>
              EVENTS
      </Typography>
      {eventDataLoad? (<Skeleton variant="card" width={"100%"} height={200} />) : (<ReturnEventData/>)}
    </MDBContainer>
    
  )
}

export default Tongaat