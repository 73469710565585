import React, { useState, useEffect, } from 'react';
import Axios from 'axios';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
import { useNavigate, useLocation } from "react-router-dom";
import { Alert } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LoadingSpinner from '../components/LoadingSpinner';

function UpdatePassword() {
    const location = useLocation()
    const [password, setPassword] = useState("")
    const [userData, setUserData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [queryStatus, setQueryStatus] = useState("")
    const [alert, setAlert] = useState(false)
    const navigate = useNavigate()
    const user = location.state
    const UpdatePwd = () => {
        Axios.post("https://backend.cjncode.co.za/updatePwd",
         {
            user: user,
            password: password
         }, setIsLoading(true)
        ).then((response) => {
          navigate("/")
        })
    }


  return (

    <MDBContainer fluid className="p-3 my-5">
      
       
      <MDBRow>

        <MDBCol col='10' md='6'>
          <img src="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/BlackLogo.png" className="img-fluid" alt="Phone image" />
        </MDBCol>
   
        <MDBCol col='4' md='6'>
        {isLoading ? <LoadingSpinner /> : <></>}
        { alert? <Alert severity="error">{queryStatus}</Alert> : <></>}
          <MDBInput wrapperClass='mb-4' label='Enter New Password'  type='email' size="lg" required={true} onChange={(e) => {
            setPassword(e.target.value)
          }}/>

          <MDBBtn className="mb-4 w-100" size="lg"  onClick={UpdatePwd} style={{backgroundColor: 'black'}} ><VpnKeyIcon style={{marginRight: 10, marginBottom: 8}}/>Update Password</MDBBtn>


        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default UpdatePassword;