import React, {useEffect, useState} from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBTypography } from 'mdb-react-ui-kit';
import Axios from  'axios'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MapIcon from '@mui/icons-material/Map';
import CakeIcon from '@mui/icons-material/Cake';
import EditIcon from '@mui/icons-material/Edit';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { Row } from 'react-bootstrap';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { Link } from 'react-router-dom';
export default function UserProfile() {
    const [userData, setUserData] = useState([]);
    const userKey = localStorage.getItem('user')
    const image = localStorage.getItem('picture')
    useEffect(() => {
        Axios.post("https://backend.cjncode.co.za/getUserData", 
            {
                userKey: userKey
            }
        ).then((response) => {
            setUserData(response.data)
        })
    }, [])
  return (
    <div className="gradient-custom-2" style={{ marginTop: 50 }}>
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="9" xl="7">
            {userData.map((val, key) => (
            <MDBCard>
              <div className="rounded-top text-white d-flex flex-row" style={{ backgroundColor: '#000', height: '200px' }}>
                <div className="ms-4 mt-5 d-flex flex-column" style={{ width: '150px' }}>
                  <MDBCardImage src= {val.profilePhoto}
                    alt="Generic placeholder image" className="mt-4 mb-2 img-thumbnail" fluid style={{ width: '150px', zIndex: '1', borderRadius: 100 }} />
                  <MDBBtn outline color="dark" style={{height: '36px', overflow: 'visible'}}>
                    
                  </MDBBtn>
                </div>
                <div className="ms-3" style={{ marginTop: '130px' }}>
                  <MDBTypography tag="h5">{val.name} {val.surname}</MDBTypography>
                  <MDBCardText>{val.department}</MDBCardText>
                  
                </div>
              </div>
              <div className="p-4 text-black" style={{ backgroundColor: '#f8f9fa' }}>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>

                <Link to="/editProfile" style={{textDecoration: 'none', color: 'inherit'}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <EditIcon/>
                    <MDBCardText className="font-italic mb-1">Edit Profile</MDBCardText>
                </div>
                </Link>
                </div>
              </div>
              <MDBCardBody className="text-black p-4">
              <div className="mb-5">
                  <p className="lead fw-normal mb-1"><AlternateEmailIcon/> E-mail Address</p>
                  <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                    <MDBCardText className="font-italic mb-1">{val.email}</MDBCardText>
                  </div>
                </div>
                <div className="mb-5">
                  <p className="lead fw-normal mb-1"><SmartphoneIcon/> Contact Number</p>
                  <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                    <MDBCardText className="font-italic mb-1">{val.contactNo}</MDBCardText>
                  </div>
                </div>
                <div className="mb-5">
                  <p className="lead fw-normal mb-1"><MapIcon/> Physical Address</p>
                  <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                    <MDBCardText className="font-italic mb-1">{val.address}</MDBCardText>
                  </div>
                </div>
                <div className="mb-5">
                  <p className="lead fw-normal mb-1"><CakeIcon/> Birthday</p>
                  <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                    <MDBCardText className="font-italic mb-1">{val.birthday}</MDBCardText>
                  </div>
                </div>
                <div className="mb-5">
                  <p className="lead fw-normal mb-1"><FingerprintIcon/> ID Number</p>
                  <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                    <MDBCardText className="font-italic mb-1">{val.IDNum}</MDBCardText>
                  </div>
                </div>
              </MDBCardBody>
            </MDBCard>
            ))}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}