import { MDBContainer } from 'mdb-react-ui-kit'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import Stories from 'react-insta-stories'
import Axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
function StoriesPage() {
    let i =0 
    const [storyData, setStoryData] = useState([])
    const location = useLocation()
    const Data = location.state.data
    const Key = location.state.key
    console.log(Key)
    console.log(Data)
    const navigate = useNavigate()
    console.log(Data)
    while(i<Data.length){
    Data[i].header= {heading: 'NCF North', subheading: Key, profileImage: 'https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/reelHeading.png'}
    i++
    }
    console.log(Data.length)
  return (
    
    <Stories 
          stories={Data.filter((item) => item.storyTitle===Key)}
          defaultInterval={2500}
          width={'100%'}
          preventDefault ={false}
          height={'100vh'}
          onAllStoriesEnd={() => navigate('/home')}
          
    />
    
  )
}

export default StoriesPage;