import React, { useState } from 'react';
import Axios from 'axios';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';
import { Alert } from '@mui/material';
import { useNavigate } from "react-router-dom";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LoadingSpinner from '../components/LoadingSpinner';
function Register() {
  const [passwordType, setPasswordType] = useState("password");
    const [regName, setRegName] = useState("");
    const [regSurname, setRegSurname] = useState("");
    const [regAddress, setRegAddress] = useState("");
    const [regNumber, setRegNumber] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [regEmail, setRegEmail] = useState("");
    const [regPassword, setRegpassword] = useState("");
    const navigate = useNavigate();
    const [registerStatus, setRegisterStatus] = useState("")
    const [alert, setAlert] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false)
    const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       return;
      }
      setPasswordType("password")
    }
    const RegisterAction = () => {
      
        Axios.post("https://backend.cjncode.co.za/register", 
            {
                sendName: regName,
                sendSurname: regSurname,
                sendAddress: regAddress,
                sendNumber: regNumber,
                sendEmail: regEmail,
                sendPassword: regPassword
            },  setIsLoading(true), setBtnDisabled(true)).then((response) => {
              if(response.data.message){
                setIsLoading(false)
                setRegisterStatus(response.data.message)
                setAlert(true)
                setBtnDisabled(false)
              }else{
                setIsLoading(false)
                navigate("/login")
                
              }
            })
    }

  return (
    <MDBContainer fluid className="p-3 my-5">
    
      <MDBRow>

        <MDBCol col='10' md='6'>
        { alert? <Alert severity="error">{registerStatus}</Alert> : <></>}
          <img src="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/BlackLogo.png" className="img-fluid" alt="Phone image" />
        </MDBCol>
        {isLoading ? <LoadingSpinner /> : <></>}
        <MDBCol col='4' md='6'>
        <MDBInput wrapperClass='mb-4' label='Name'  type='text' size="lg" onChange={(e) => {
            setRegName(e.target.value)
          }}/>
                    <MDBInput wrapperClass='mb-4' label='Surname'  type='text' size="lg" onChange={(e) => {
            setRegSurname(e.target.value)
          }}/>
                    <MDBInput wrapperClass='mb-4' label='Address'  type='text' size="lg" onChange={(e) => {
            setRegAddress(e.target.value)
          }}/>
                    <MDBInput wrapperClass='mb-4' label='Contact Number'  type='text' size="lg" onChange={(e) => {
            setRegNumber(e.target.value)
          }}/>

          <MDBInput wrapperClass='mb-4' label='Email address'  type='email' size="lg" onChange={(e) => {
            setRegEmail(e.target.value)
          }}/>
          <MDBInput wrapperClass='mb-4' label='Password'  type={passwordType} size="lg" onChange={(e) => {
            setRegpassword(e.target.value)
          }}/>
            <div className="d-flex justify-content-between mx-4 mb-4">
            <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Show Password' onClick={togglePassword}/>
            
          </div>


          <MDBBtn className="mb-4 w-100" size="lg" disabled={btnDisabled} onClick={RegisterAction} style={{backgroundColor:'black'}}> <AppRegistrationIcon style={{marginRight: 10, marginBottom: 8}}/>SIGN UP</MDBBtn>
          {/* <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: '#55acee'}}>
            <MDBIcon fab icon="twitter" className="mx-2"/>
            Continue with twitter
          </MDBBtn> */}
                    <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: 'grey'}} onClick={() => navigate("/")}>
            <MDBIcon fab icon="facebook-f" className="mx-2" />
              <VpnKeyIcon style={{marginRight: 10, marginBottom: 8}}/>LOGIN
          </MDBBtn>
        </MDBCol>

      </MDBRow>

    </MDBContainer>
  );
}

export default Register;