import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'

function MaybeShowNav({children}) {
    const location = useLocation();
    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(()=>{
        console.log(location);
        if(location.pathname==="/" || location.pathname==="/register" || location.pathname==="/resetPassword" || location.pathname==="/updatePassword" || location.pathname==="/stories"){
            setShowNavbar(false)
        }else{
            setShowNavbar(true)
        }
    },[location])
  return (
    <div>{showNavbar && children}</div>
  )
}

export default MaybeShowNav