import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
}
from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";


function EditProfile() {

    const userKey = localStorage.getItem("user");

    useEffect(() => {
        Axios.post("https://backend.cjncode.co.za/getUserData",
         {
            userKey: userKey
         }
        ).then((response) => {
            setOldUserData(response.data)
            localStorage.setItem('name', response.data[0].name);
            localStorage.setItem('surname', response.data[0].surname);
            localStorage.setItem('address', response.data[0].address);
            localStorage.setItem('contactNo', response.data[0].contactNo);
            localStorage.setItem('birthday', response.data[0].birthday);
            localStorage.setItem('ID', response.data[0].IDDNum)
        })
    })

    const [newName, setNewName] = useState(localStorage.getItem('name'));
    const [newSurname, setNewSurname] = useState(localStorage.getItem('surname'));
    const [newAddress, setNewAddress] = useState(localStorage.getItem('address'));
    const [newNumber, setNewNumber] = useState(localStorage.getItem('contactNo'));
    const [newBirthday, setNewBirthday] = useState(localStorage.getItem('birthday'));
    const [IDNum, setIDNum] =useState(localStorage.getItem('ID'));
    const navigate = useNavigate();
    const [oldUserData, setOldUserData] = useState([]);

    const handleEdit = () => {
        Axios.post("https://backend.cjncode.co.za/editUserData", 
            {
                newName: newName,
                newSurname: newSurname,
                newAddress: newAddress,
                newNumber: newNumber,
                newBirthday: newBirthday,
                IDNum: IDNum,
                userKey: userKey

            }
        ).then((response) => {
            navigate("/profile")
        })
    }
  return (
    <MDBContainer fluid className="p-3 my-5">
    
    <MDBRow style={{marginTop: 50}}>

      <MDBCol col='10' md='6'>
      
      </MDBCol>
      {oldUserData.map((val,key) => (
      <MDBCol col='4' md='6'>
       
      <MDBInput wrapperClass='mb-4' label='Name'  type='text' size="lg" defaultValue={val.name} aria-controls onChange={(e) => {
          setNewName(e.target.value)
        }}/>
                  <MDBInput wrapperClass='mb-4' label='Surname'  type='text' size="lg" defaultValue={val.surname} onChange={(e) => {
          setNewSurname(e.target.value)
        }}/>
                  <MDBInput wrapperClass='mb-4' label='Address'  type='text' size="lg" defaultValue={val.address} onChange={(e) => {
          setNewAddress(e.target.value)
        }}/>
                  <MDBInput wrapperClass='mb-4' label='Contact Number'  type='text' size="lg" defaultValue={val.contactNo} onChange={(e) => {
          setNewNumber(e.target.value)
        }}/>

        <MDBInput wrapperClass='mb-4' label='Birthday'  type='email' size="lg" defaultValue={val.birthday} onChange={(e) => {
          setNewBirthday(e.target.value)
        }}/>
                <MDBInput wrapperClass='mb-4' label='ID Number'  type='email' size="lg" defaultValue={val.IDNum} onChange={(e) => {
          setIDNum(e.target.value)
        }}/>
                  <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: 'grey'}} onClick={handleEdit}>
          <MDBIcon fab icon="facebook-f" className="mx-2" />
            UPDATE PROFILE
        </MDBBtn>
       
      </MDBCol>
))}
    </MDBRow>

  </MDBContainer>
  )
}

export default EditProfile