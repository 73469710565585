import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes, Router } from "react-router-dom";
import Login from './Pages/Login';
import VideoPlayer from './Pages/VideoPlayer';
import Register from './Pages/Register';
import HomePage from './Pages/HomePage';
import VideoPage from './Pages/VideoPage';
import AudioPage from './Pages/AudioPage';
import Verulam from './Pages/Verulam';
import Navigation from './components/Navigator';
import MaybeShowNav from './components/MaybeShowNav';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import MaybeShowBar from './components/MaybeShowBar';
import GivinigPage from './Pages/GivinigPage';
import swDev from './swDev';
import MaybeShowTab from './components/MaybeShowTab';
import TabNav from './components/TabNav';
import Tongaat from './Pages/Tongaat';
import Stanger from './Pages/Stanger';
import Johannesburg from './Pages/Johannesburg';
import Mandini from './Pages/Mandini';
import Details from './components/Details';
import EventData from './components/EventData';
import Intercession from './Pages/Intercession';
import Empowerment from './Pages/Empowerment';
import Leadership from './Pages/Leadership';
import VideoLanding from './Pages/VideoLanding';
import AudioLanding from './Pages/AudioLanding';
import Wallpaper from './Pages/Wallpaper';
import Departments from './Pages/Departments';
import SpEventData from './components/SpEventData';
import EVPlayer from './Pages/EVPlayer';
import Bible from './Pages/Bible';
import { AuthContext } from './components/authContext';
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>

);
swDev();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
