import React, {useState, useEffect} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

function GivinigPage() {
  return (
    <MDBContainer fluid className="p-3 my-5" >
        
         <Card sx={{  marginTop: 5}} >
        
      <CardActionArea>
      <div>
        <Link to={"https://www.paypal.com/donate?token=Jq9syMbwNFpt5O61i71KmlbjGuJyFO5RO74EH70IrCQ_OxUp9Q6BcgLtC_WPNgp02peM5OnHScCJmaOB&locale.x=EN_US"}>
        <CardMedia
          component="img"
          height="140"
          image="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/paypal.png"
          alt="PayPal"
        />
        </Link>
        </div>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" style={{fontWeight: 'bold', textAlign: 'center'}}>
            PAYPAL
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{textAlign: 'center'}}>
            Make secure and fast payments to our church with paypal
          </Typography>
          <Typography variant="body2" color="text.primary">
            
          </Typography>
          <Typography variant="body2" color="text.primary">
            
          </Typography>
          <Typography variant="body2" color="text.primary">
            
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
    

    <Card sx={{  marginTop: 5}} >
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/bd.jpg"
          alt="PayPal"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" style={{fontWeight: 'bold', textAlign: 'center'}}>
            EFT
          </Typography>
          <Typography variant="body2" color="text.secondary"  style={{ textAlign: 'center'}}>
           Make Electronic transfers with our Standard Bank Details
          </Typography>
          <Typography variant="body2" color="text.primary">
            
          </Typography>
          <Typography variant="body2" color="text.primary">
            
          </Typography>
          <Typography variant="body2" color="text.primary">
            
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>


    </MDBContainer>
  )
}

export default GivinigPage