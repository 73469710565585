import React, {useState, useEffect} from 'react'
import Skeleton from '@mui/material/Skeleton';
import {
  MDBContainer, MDBValidation
} from 'mdb-react-ui-kit';
import Card from '@mui/material/Card';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Dialog, Slide, LinearProgress } from '@mui/material';
import Modal from 'react-modal';
import Axios from 'axios'
import ReactPlayer from 'react-player';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import { requestForToken } from '../Firebase';
import Stories from 'react-insta-stories'
import { useLocation, useNavigate } from 'react-router-dom';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HomePage() {
  const navigate = useNavigate()
  const [homeData, setHomeData] = useState([])
  const [videoData, setVideoData] = useState([])
  const [homeDataLoad, setHomeDataLoad] = useState(true)
  const [videoDataLoad, setVideoDataLoad] = useState(true)
  const [storyData1, setStoryData1] = useState([])
  const [storyObj, setStoryObj] = useState([])
  const [liveVideoData, setLiveVideo] = useState([])
  const [storyDataLoad, setStoryDataLoad] = useState(true)
  const [highlightDataLoad, setHighlightDataLoad] = useState(true)
   let count = 0

  
const header = [
  {
    heading: "hi"
  }
]

  useEffect(() => {
    Axios.post("https://backend.cjncode.co.za/get30Day").then((response) => {
      setHomeData(response.data)
      setHomeDataLoad(false)
    })
  },[])


  const [open, setOpen] = React.useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const locate = useLocation()

  const handleClickOpen = (val, state) => {
    setSelectedProject(val);
   
    
      setOpen(true);
  };
  useEffect(() => {
    Axios.post("https://backend.cjncode.co.za/getLiveVideo").then((response) => {
      setLiveVideo(response.data)
      setHighlightDataLoad(false)
    })
  }, [])


  useEffect(() => {
    Axios.post("https://backend.cjncode.co.za/getStory1").then((response) => {
      setStoryData1(response.data)
      console.log(response.data)
      })
  },[])

  useEffect(() => {
    Axios.post("https://backend.cjncode.co.za/getStory").then((response) => {
      setStoryObj(response.data)
      setStoryDataLoad(false)
    })
  },[])

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    Axios.post("https://backend.cjncode.co.za/getVOD").then((response) => {
      setVideoData(response.data);
      setVideoDataLoad(false)
    })
  },[])

  




  const decreaseCount = () => {
    if(count <=  0) {
      count = 0
    } else {
      count = count -1
    }
    console.log(count)
  }

  const increaseCount = () => {
    count = count + 1
    console.log(count)
  }
  

  const ReturnHomeData = () => {
    return(
    <MDBContainer>
    
      <Card sx={{ width:'100%', alignSelf: 'center', marginBottom: 5, borderRadius: 5}} >
      <CardActionArea>
        <CardMedia
          component="img"
          height="190"
          image="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/home.jpg"
          alt="green iguana"
          style={{borderRadius: 5}}
        />
        
      </CardActionArea>
      </Card>
     
      
       </MDBContainer>
    )
  }



  const ReturnVideoData = () => {
    return (
      <MDBContainer style={{overflowX: 'scroll'}}>
      <div style={{
            display: 'flex',
            overflowX: 'scroll',
            width: '100%',
            flexDirection: 'row',
      }}>
       {videoData.map((val, key) => (
        <div key={key}>
          <div
            
            style={{
              flexShrink: 0,
              width: '350px',
              height: '200px',
              borderRadius: '40px',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              padding: 5
            }}

          > 
          <ReactPlayer url={val.videoURL} width={"100%"} height={'100%'} style={{borderRadius: 40}} controls={true}   config={{
    youtube: {
      playerVars: { showinfo: 1 }
    },}}
    />

          </div>

      </div>
))}

          </div>
       
       </MDBContainer>
            )
  }

  const ReturnStoryData = () => {
    return (
      <div style={{ height: 200, display: 'flex', overflowX: 'scroll',  flexDirection: 'row', padding: 5}}>
      {storyObj.map((val, key) => (
        <div style={{ height: '100%', width: '35%', borderRadius: 25, flexShrink: 0, marginRight: 8, position: 'relative', border: '1px solid'}} onClick={()=>navigate('/stories', {state: {data: storyData1, key: val.storyKey}})} key={key}>
             <img src={val.storyImg} style={{width: '100%', height: '100%', borderRadius: 25, position: 'absolute'}}/>
             <div style={{background: 'none', width: '35%', height: '25%', position: 'absolute', zIndex: 10, margin: '8%', borderRadius: 50, padding: '3%', border: '3.5px solid #0047AB	'}}><img src="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/BlackLogo.jpg" style={{width: '100%', borderRadius: 50}}/></div>
        </div>
        ))}
      </div>
    )
  }
  
  const ReturnHighlightData = () => {
    return(
      <div style={{width: '100%', height: '80%'}}>
      {liveVideoData.map((val, key) => (
        <ReactPlayer url={val.liveURL} width={'100%'} height={250} controls={true} config={{
          youtube: {
            playerVars: { showinfo: 1 }
          },}} key={key}/>
      ))}  
      </div>
    )
  }
 
  return (
    
    <MDBContainer style={{flexDirection: 'column', overflowX: 'scroll', }}>
      
      {storyDataLoad? (<Skeleton variant="card" width={"35%"} height={200} style={{borderRadius: 25, marginTop: 10}} />) : (<ReturnStoryData/>)}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignContent: 'center', alignItems: 'center', marginTop: '10%'}}>
    <Typography gutterBottom variant="h5" component="div" style={{alignSelf: 'center', fontWeight: 'bold', }}>
              WELCOME TO NCF NORTH
              
      </Typography>
      <img src="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/BlackLogo.png" style={{width: '8%', height: '8%', marginBottom: '2%'}}/>
      </div>
      {homeDataLoad? (<Skeleton variant="card" width={"100%"} height={190} style={{borderRadius: 25}}/>) : (<ReturnHomeData/>) }
      <div>
      <Typography gutterBottom variant="h5" component="div" style={{alignSelf: 'center', fontWeight: 'bold', }}>
              HERE'S WHAT YOU'VE MISSED
              
      </Typography> 
      {highlightDataLoad? (<Skeleton variant="card" width={"100%"} height={250} style={{borderRadius: 5}}/>) : (<ReturnHighlightData/>) } 
      </div>
      <Typography gutterBottom variant="h5" component="div" style={{alignSelf: 'center', marginTop: '10%', fontWeight: 'bold', }}>
              SOME RECENT CONTENT
           
      </Typography>
      {videoDataLoad ? (<Skeleton variant="card" width={"100%"} height={'200px'} />) : (<ReturnVideoData/>)}
      
      </MDBContainer>
  
  );
}