import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {
  MDBContainer,
} from 'mdb-react-ui-kit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Axios from 'axios'


function Departments() {
    const[departments, setDepartments] = useState ([])
  const navigate = useNavigate();

    useEffect(() => {
        Axios.post("https://backend.cjncode.co.za/getDepartments").then((response) => {
            setDepartments(response.data)
        })
    }, [])
  return (
    <MDBContainer fluid className="p-3" style={{marginTop: '20%'}}>

    {departments.map((val, key) => (
    <Card sx={{ width:'100%', alignSelf: 'center', marginBottom: 5}}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="240"
            image={val.departmentURL}
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
             
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontWeight: 'bold'}}>
                {val.departmentDescription}
            </Typography>
          </CardContent>
        </CardActionArea>
        </Card>
))}
        
    </MDBContainer>

  )
}

export default Departments