import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HandymanIcon from '@mui/icons-material/Handyman';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const pages = ['Products', 'Pricing', 'Blog'];
const settings = ['Profile', 'Account', 'Logout'];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate()

  return (
    <AppBar position="fixed" style={{backgroundColor: 'black', height: '8%'}} elevation={10} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
         
          <Typography
          
            
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
       
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
             
            </IconButton>
            {/*  */}
            <div></div>
          </Box>
         
          <Typography

            sx={{
              ml: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              
            }}
          >
            <img src="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/headerCrop.bg.png" style={{height: 18}}/>
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <MenuIcon onClick={handleOpenUserMenu} sx={{ p: 0 }}>
               
              </MenuIcon>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              
                <MenuItem  onClick={() => (navigate("/bible"), handleCloseUserMenu())}>
                  <Typography textAlign="center"><MenuBookIcon/> Bible</Typography>
                </MenuItem>
                <MenuItem  onClick={() => (navigate('/profile'), handleCloseUserMenu())}>
                  <Typography textAlign="center"><AccountCircleIcon/> My Profile</Typography>
                </MenuItem>
                <MenuItem  onClick={() => (navigate("/departments"), handleCloseUserMenu())}>
                  <Typography textAlign="center"><HandymanIcon/> Departments</Typography>
                </MenuItem>
                <MenuItem  onClick={() => (navigate('/wallpaper'), handleCloseUserMenu())}>
                  <Typography textAlign="center"><WallpaperIcon/> Wallpapers</Typography>
                </MenuItem>
                <MenuItem  onClick={() => (navigate('/contact'), handleCloseUserMenu())}>
                  <Typography textAlign="center"><ContactPageIcon/> Contact Us</Typography>
                </MenuItem>
                <MenuItem  onClick={() => navigate('/', localStorage.removeItem('isLoggedIn'))}>
                  <Typography textAlign="center"><ExitToAppIcon/> Logout</Typography>
                </MenuItem>

            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;