import React, { useState } from "react";
import Axios from 'axios';
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBBtn, MDBIcon} from "mdb-react-ui-kit";

const EditPicture = () => {

  const [selectedImage, setSelectedImage] = useState(null);
    const navigate = useNavigate()
    const userKey = localStorage.getItem('user')
    const handleSubmit = () => {
     localStorage.setItem("picture", URL.createObjectURL(selectedImage))
     const varImage = URL.createObjectURL(selectedImage);
     Axios.post("https://backend.cjncode.co.za/uploadImage",
      {
        imageURL : varImage,
        userKey: userKey
      }
     ).then((response) => {
        navigate("/profile")
     })
    }
  return (
    <MDBContainer className="py-5 h-100" style={{marginTop: 50, display: 'flex', flexDirection: "column", justifyContent: 'space-between', alignContent: 'center'}}>
    <div style={{alignContent: 'center', alignItems: 'center'}}>
      <h1>Upload An Image To Your Profile</h1>
      {selectedImage && (
        <div style={{alignContent: 'center', alignItems: 'center'}}>
          <img
            alt="not found"
            width={"250px"}
            src={URL.createObjectURL(selectedImage)}
          />
          <br />
          <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: 'grey', marginTop: 20}} onClick={() => setSelectedImage(null)}>
          
            REMOVE IMAGE
        </MDBBtn>
        </div>
      )}

      <br />
      <br />
      
      <input
        type="file"
        name="myImage"
        className="mb-4 w-100"
        size="lg"
        onChange={(event) => {
          console.log(event.target.files[0].name);
          setSelectedImage(event.target.files[0]);
          console.log(event.target.files)
          
        }}
      />
                <MDBBtn className="mb-4 w-100" size="lg" style={{backgroundColor: 'black', marginTop: 20}} onClick={handleSubmit}>
          
         UPLOAD IMAGE
      </MDBBtn>
    </div>
    </MDBContainer>  
    );
};

export default EditPicture;
