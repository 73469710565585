import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'

function MaybeShowTab({children}) {
    const location = useLocation();
    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(()=>{
        console.log(location);
        if(
          location.pathname==="/home" ||
          location.pathname==="/verulam" || 
          location.pathname==="/tongaat" || 
          location.pathname==="/stanger" || 
          location.pathname==="/mandini" || 
          location.pathname==="/johannesburg"){
            setShowNavbar(true)
        }else{
            setShowNavbar(false)
        }
    },[location])
  return (
    <div>{showNavbar && children}</div>
  )
}

export default MaybeShowTab