import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import {
  MDBContainer,
} from 'mdb-react-ui-kit';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Axios from 'axios'


function VideoLanding() {

  const navigate = useNavigate();


  return (
    <MDBContainer fluid className="p-3" style={{marginTop: '20%'}}>

    
    <Card sx={{ width:'100%', alignSelf: 'center', marginBottom: 5}} onClick={() => navigate('/video')}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="240"
            image="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/vid_series.jpg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
             
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontWeight: 'bold'}}>
                BE REFRESHED WITH OUR VIDEO LIBRARY
            </Typography>
          </CardContent>
        </CardActionArea>
        </Card>

        <Card sx={{ width:'100%', alignSelf: 'center', marginBottom: 5}} onClick={() => navigate('/empowerment')}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="240"
            image="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/emp_video.jpg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
             
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontWeight: 'bold'}}>
                STAY EMPOWERED WITH OUR WEEKLY EMPOWERMENTS
            </Typography>
          </CardContent>
        </CardActionArea>
        </Card>

        <Card sx={{ width:'100%', alignSelf: 'center', marginBottom: 5}} onClick={() => navigate('/leadership')}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="240"
            image="https://ncfnmediarepo.cjncode.co.za/ncfn_cloud_media_repo/resources.jpg"
            alt="green iguana"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
             
            </Typography>
            <Typography variant="body2" color="text.secondary" style={{fontWeight: 'bold'}}>
                GET OUR DOCUMENT RESOURCES
            </Typography>
          </CardContent>
        </CardActionArea>
        </Card>
    </MDBContainer>

  )
}

export default VideoLanding