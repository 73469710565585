import React, {useEffect, useState} from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Axios from 'axios'
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBTypography } from 'mdb-react-ui-kit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoIcon from '@mui/icons-material/Info';
import ReactPlayer from 'react-player';
function SpEventData() {
    const location = useLocation()
    const eventKey = location.state
    console.log(eventKey);
    const [eventDes, setEventDes] =  useState([])
    useEffect(() => {
        Axios.post("https://backend.cjncode.co.za/getSpEventData",
            {
                eventKey : eventKey
            }
        ).then((response) => {
            setEventDes(response.data)
            console.log(response)
        })
    }, [])
  return (
   
    
   
      <MDBContainer style={{height: '100%'}}>
            {eventDes.map((val, key) => (
                <div style={{height: '100%', boxShadow: '1px 2px 9px grey'}}>
                    <img src={val.eventVideo}   style={{marginTop: 15, width:'100%'}}/>
                    <div style={{height: '100%', width: '100%', backgroundColor: '#D3D3D3', borderTopLeftRadius: 50, borderTopRightRadius: 50}}>
                        <div style={{display: 'flex', flexDirection: 'column', padding: 5, justifyContent: 'space-between', alignItems: 'center'}}>
                            <h6 style={{color: 'white', fontSize: 28, padding: 10, textDecoration: 'underline', fontWeight: 'bold'}}>{val.eventTitle}</h6>
                            <div style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
                                <div  style={{alignItems: 'center', textAlign: 'center', marginTop: 10}}>
                                    <CalendarMonthIcon fontSize='large' color='#ffffff'/>
                                    <h6 style={{fontSize: 20, color: 'white', fontWeight: 'bold'}}>Event Date: </h6>
                                    <h6 style={{fontSize: 18, color: 'white', fontWeight: 'bold', textDecoration: 'underline'}}>{val.eventDates.toString()}</h6>
                                </div>
                            </div>
                            <div style={{alignItems: 'center', textAlign: 'center', marginTop: 30}}>
                               <h6 style={{fontSize: 20, color: 'white', fontWeight: 'bold'}}>Event Description</h6> 
                               <h6 style={{fontSize: 18, color: 'white', }}>{val.eventDescription}</h6>
                            </div>

                            <div style={{alignItems: 'center', textAlign: 'center', marginTop: 30}}>
                               <h6 style={{fontSize: 20, color: 'white', fontWeight: 'bold'}}>Information</h6> 
                               <h6 style={{fontSize: 18, color: 'white', }}>For More information on the particular event, please see one of our attendees at our information counter on Sunday!</h6>
                            </div>
                        </div>
                    </div>
                </div>
            ))}


        </MDBContainer>



  )
}

export default SpEventData