import React, { useState, createContext, useContext } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import BottomNavigation from '@mui/material/BottomNavigation';
import { BrowserRouter, Route, Routes, Router,Switch } from "react-router-dom";

import Login from '../Pages/Login';
import VideoPlayer from '../Pages/VideoPlayer';
import Register from '../Pages/Register';
import HomePage from '../Pages/HomePage';
import VideoPage from '../Pages/VideoPage';
import AudioPage from '../Pages/AudioPage';
import Verulam from '../Pages/Verulam';
import Navigation from '../components/Navigator';
import MaybeShowNav from '../components/MaybeShowNav';

import MaybeShowBar from '../components/MaybeShowBar';
import GivinigPage from '../Pages/GivinigPage';
import swDev from '../swDev';
import MaybeShowTab from '../components/MaybeShowTab';

import Tongaat from '../Pages/Tongaat';
import Stanger from '../Pages/Stanger';
import Johannesburg from '../Pages/Johannesburg';
import Mandini from '../Pages/Mandini';
import Details from '../components/Details';
import EventData from '../components/EventData';
import Intercession from '../Pages/Intercession';
import Empowerment from '../Pages/Empowerment';
import Leadership from '../Pages/Leadership';
import VideoLanding from '../Pages/VideoLanding';
import AudioLanding from '../Pages/AudioLanding';
import Wallpaper from '../Pages/Wallpaper';
import Departments from '../Pages/Departments';
import SpEventData from '../components/SpEventData';
import EVPlayer from '../Pages/EVPlayer';
import Bible from '../Pages/Bible';
import UpdatePassword from '../Pages/UpdatePassword';


import ResponsiveAppBar from '../components/ResponsiveAppBar';
import TabNav from '../components/TabNav';
import UserProfile from '../Pages/UserProfile';
import EditProfile from '../Pages/EditProfile';
import EditPicture from '../Pages/EditPicture';
import ReadBible from '../Pages/ReadBible';
import ResetPassword from '../Pages/ResetPassword';
import Stories from '../Pages/Stories';
import StoriesPage from '../Pages/Stories';
import Contact from '../Pages/Contact';


function AppStack() {
  const isLoggedin = localStorage.getItem('isLoggedIn')
  return (
    <div>
    <MaybeShowBar>
    <ResponsiveAppBar/>
  </MaybeShowBar>
  <MaybeShowTab>
    <TabNav/>
  </MaybeShowTab>
      <Routes>
        <Route path="/" element={<Login/> } />
        <Route path="/register" element={<Register />} />
        <Route path="/home" element={<HomePage/>} />
        <Route path="/video" element={<VideoPage/>} />
        <Route path="/audio" element={<AudioPage/>} />
        <Route path="/vplayer" element={<VideoPlayer/>} />
        <Route path="/giving" element={<GivinigPage/>} />
        <Route path='/verulam' element={<Verulam/>} />
        <Route path="/tongaat" element={<Tongaat/>}/>
        <Route path="/details" element={<Details/>}/>
        <Route path="/stanger" element={<Stanger/>} />
        <Route path="/mandini" element={<Mandini/>} />
        <Route path="/johannesburg" element={<Johannesburg/>}/>
        <Route path="/events" element={<EventData/>} />
        <Route path="/intercession" element={<Intercession/>} />
        <Route path="/empowerment" element={<Empowerment/>} />
        <Route path="/leadership" element={<Leadership/>}/>
        <Route path="/videoLanding" element={<VideoLanding/>} />
        <Route path="/audioLanding" element={<AudioLanding/>}/>
        <Route path="/wallpaper" element={<Wallpaper/>} />
        <Route path="/departments" element={<Departments/>}/>
        <Route path="/spEventData" element={<SpEventData/>} />
        <Route path="/evplayer" element={<EVPlayer/>} />
        <Route path="/bible" element={<Bible/>} />
        <Route path="/profile" element={<UserProfile/>} />
        <Route path="/editProfile" element={<EditProfile/>}/>
        <Route path="/editPicture" element={<EditPicture/>} />
        <Route path="/readBible" element={<ReadBible/>} />
        <Route path="/resetPassword" element={<ResetPassword/>} />
        <Route path="/updatePassword" element={<UpdatePassword/>} />
        <Route path="/stories" element={<StoriesPage/>}/>
        <Route path='/contact' element={<Contact/>} />
      </Routes>
  <MaybeShowNav>
    <Navigation/>
  </MaybeShowNav>
  </div>
  )
}

export default AppStack