import React, {useState, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {
  MDBContainer,
} from 'mdb-react-ui-kit';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import Axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ReactAudioPlayer from 'react-audio-player';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Link } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Leadership = () => {
  const [audioData, setAudioData] = useState([]);

  useEffect(()=>{
    Axios.post("https://backend.cjncode.co.za/getLeadership").then((response)=> {
      setAudioData(response.data)
    })
  },[])


// Dialog config
  const [open, setOpen] = React.useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const handleClickOpen = (val) => {
    setSelectedProject(val);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MDBContainer style={{marginTop: '18%'}}>
    <MDBContainer fluid className="p-3 my-5" >
      {audioData.map((val, key) => (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} style={{marginTop: 5}} key={key}>
      <ListItem alignItems="flex-start"  >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={val.documentArt} />
          {console.log(val.documentArt)}
        </ListItemAvatar>
        <ListItemText
          primary={val.documentTitle} style={{fontweight: 'bold'}}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {val.documentDescription}
              </Typography>
            </React.Fragment>
          }
        />
        <Link style={{
          textDecoration: 'none',
          color: 'inherit'
        }}
        to={val.documentURL}
        download="PDF-document"
        target="_blank"
        rel="noreferrer"
        >
        <CloudDownloadIcon style={{width:45, height: 50}}/>
        </Link>
        <Dialog
          fullWidth
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            key={key}
          >
            <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
              <Toolbar style={{backgroundColor: 'black'}}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  
                </Typography>
                <Button autoFocus color="inherit" onClick={handleClose}>
                  
                </Button>
              </Toolbar>
            </AppBar>
            <MDBContainer style={{alignContent: 'center', width:'100%'}}>
            <List style={{alignItems: 'center'}}>

              <Divider />
              <ListItem button style={{display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
                <ListItemText
                  primary={selectedProject && selectedProject.audioTitle}
                  secondary={selectedProject && selectedProject.audioDescription}
                />
              </ListItem>
              <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
              <Avatar alt="Remy Sharp" src={selectedProject && selectedProject.audioArt} style={{width: 130 , height: 100, alignSelf: 'center'}}/>
              <ReactAudioPlayer
                    src={selectedProject && selectedProject.audioURL}
                    controls
                    style={{alignSelf: 'center', marginTop: 10}}
                  />
                  </div>
            </List>
            </MDBContainer>
          </Dialog>
      </ListItem>
    
        <Divider variant="inset" component="li" />
        <MDBContainer style={{width: '100%'}} >

         
          </MDBContainer>

    </List>
       

))}
    </MDBContainer>

  </MDBContainer> 
  );
}

export default Leadership;