import React, {useState, useEffect} from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { MDBContainer } from 'mdb-react-ui-kit';
import Axios from 'axios'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Link } from 'react-router-dom';
export default function Wallpaper() {
    const [wallpaper, setWallpaper] = useState([])

    useEffect(() => {
        Axios.post("https://backend.cjncode.co.za/getWallpaper").then((response) => {
            setWallpaper(response.data)
        })
    })
  return (
    <MDBContainer fluid className="p-3" style={{marginTop: '18%'}} >
    <ImageList sx={{ width: '100%', height: "100%" }}>
      <ImageListItem key="Subheader" cols={2}>
        
      </ImageListItem>
      {wallpaper.map((item, key) => (
        <ImageListItem key={key}>
          <img
            src={item.wallpaperURL}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
            actionIcon={
                <Link style={{
                    textDecoration: 'none',
                    color: 'inherit'
                  }}
                  to={item.wallpaperURL}
                  download="JPG-Wallpaper"
                  target="_blank"
                  rel="noreferrer"
                  >
              <CloudDownloadIcon
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                fontSize='large'
              >
                <InfoIcon />
              </CloudDownloadIcon>
              </Link>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
    </MDBContainer>
  );
}

const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
    author: '@bkristastucchio',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
    author: '@rollelflex_graphy726',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
    author: '@helloimnik',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
    author: '@nolanissac',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
    author: '@hjrc33',
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
    featured: true,
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
    author: '@tjdragotta',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
    author: '@katie_wasserman',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
    author: '@silverdalex',
    rows: 2,
    cols: 2,
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
    author: '@shelleypauls',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
    author: '@peterlaster',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
    author: '@southside_customs',
    cols: 2,
  },
];