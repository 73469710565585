import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import {
  MDBContainer,
} from 'mdb-react-ui-kit';
import Typography from '@mui/material/Typography';
import ReactPlayer from 'react-player';
import Axios from 'axios'
function VideoPlayer() {
const location = useLocation();
const [videoData, setVideoData] = useState([]);
const videoPlaylistVar = location.state;
console.log(videoPlaylistVar)
useEffect(() => {
  Axios.post("https://backend.cjncode.co.za/getVideoData",
    {videoKey: videoPlaylistVar}
  ).then((response)=> {
    setVideoData(response.data)
    
  })
},[])
  return (
    <div>
      
        <MDBContainer className="p-3" style={{marginTop: '10%'}}>
        {videoData.map((val, key) =>(
          <MDBContainer key={key}>
        <ReactPlayer url={val.videoURL} width={"100%"} height={400} style={{marginTop: '10%'}}/>
          <React.Fragment>
                  <Typography
                    variant="body2" color="text.primary"
                    style={{fontSize: 20, textAlign: 'center', fontWeight: 'bold'}}
                  >
                    {val.videoTitle}
                  </Typography>
                  
                </React.Fragment>
                </MDBContainer>
              ))}
              </MDBContainer>
      
      </div>
    
  )
}

export default VideoPlayer;