import React, {useState, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {
  MDBContainer,
} from 'mdb-react-ui-kit';
import {  useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import Axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ReactAudioPlayer from 'react-audio-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Intercession = () => {
  const [audioData, setAudioData] = useState([]);
  const [play, setPlay] = useState(false);
  const audioRef = React.useRef(null);

  useEffect(()=>{
    Axios.post("https://backend.cjncode.co.za/getIntercession").then((response)=> {
      setAudioData(response.data)
    })
  },[])

  function toggleAudio(){
    if(play) {
      audioRef.current?.pause();
      setPlay(false)
    } else {
      audioRef.current?.play();
      setPlay(true);
    }
  }

// Dialog config
  const [open, setOpen] = React.useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const handleClickOpen = (val) => {
    setSelectedProject(val);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MDBContainer style={{marginTop: '18%'}}>
    <MDBContainer fluid className="p-3 my-5" >
      {audioData.map((val, key) => (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} style={{marginTop: 5}} key={key}>
      <ListItem alignItems="flex-start"  >
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={val.audioArt} />
        </ListItemAvatar>
        <ListItemText
          primary={val.audioTitle}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {val.audioDescription}
              </Typography>
            </React.Fragment>
          }
        />
        <PlayCircleFilledWhiteIcon style={{width:45, height: 50}} onClick={() =>handleClickOpen(val)}/>
        <Dialog
          fullWidth
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            key={key}
          >
            
            <Card sx={{ display: 'flex' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {selectedProject && selectedProject.audioTitle}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
            {selectedProject && selectedProject.audioDescription}
          </Typography>
        </CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
          
          <IconButton aria-label="play/pause" onClick={toggleAudio}>
            {!play ? (<PlayArrowIcon sx={{ height: 38, width: 38, textAlign: 'center' }} />) : (<PauseIcon sx={{ height: 38, width: 38, textAlign: 'center' }} />)}
            
          </IconButton>

        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={selectedProject && selectedProject.audioArt}
        alt="Apostle Mervyn"
      />
    </Card>
   </Dialog>
      </ListItem>
    
        <Divider variant="inset" component="li" />
        <MDBContainer style={{width: '100%'}} >

         
          </MDBContainer>
          <audio ref={audioRef} src={selectedProject && selectedProject.audioURL}/>
    </List>
       

))}
    </MDBContainer>

  </MDBContainer> 
  );
}

export default Intercession;