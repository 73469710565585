import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { MDBCol, MDBContainer, MDBRow, MDBCard, MDBCardText, MDBCardBody, MDBCardImage, MDBBtn, MDBTypography } from 'mdb-react-ui-kit';
import Axios from 'axios';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Skeleton } from '@mui/material';
function Details() {
    const location = useLocation();
    const dataKey = location.state;
    const [churchData, setChurchData] = useState([]);
    const [detailsLoader, setDetailsLoader] = useState(true)
    useEffect(() => {
        Axios.post("https://backend.cjncode.co.za/getCampus", 
            {
                churchKey: dataKey
            }
        ).then((response) => {
            setChurchData(response.data);
            setDetailsLoader(false)
        })
    }, []) 


    const ReturnDetailsData = () => {
      return(
        <div className="gradient-custom-2" style={{ backgroundColor: '#000000', marginTop: 50 }}>
        {churchData.map((val, key) => (
   
     <MDBContainer className="py-5 h-100" style={{boxShadow: '1px 2px 9px #F4AAB9'}}>
       <MDBRow className="justify-content-center align-items-center h-100">
         <MDBCol lg="9" xl="7" >
           <MDBCard >
             
             <ReactPlayer url={val.campusArt} muted playing={true} loop width={'100%'} height={'50%'} style={{zIndex: 5}}/>
               <div className="ms-4 mt-5 d-flex flex-column" style={{ width: '150px' }}>
                 {/* <MDBCardImage src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"
                   alt="Generic placeholder image" className="mt-4 mb-2 img-thumbnail" fluid style={{ width: '150px', zIndex: '1' }} /> */}
                 {/* <MDBBtn outline color="dark" style={{height: '36px', overflow: 'visible'}}>
                   Edit profile
                 </MDBBtn> */}
               </div>
               
               {/* <div className="ms-3" style={{ marginTop: '130px' }}>
                 <MDBTypography tag="h5">TONGAAT</MDBTypography>
                 <MDBCardText>Campus</MDBCardText>
               </div> */}
            
             <div className="p-4 text-black" style={{ backgroundColor: 'white' , textAlign: 'center'}}>
               <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                 <div>
                 <Link
                                           style={{
                                             textDecoration: 'none',
                                             color: 'inherit'
                                           }}
                                           to={val.campusMap}
                                           target="_blank"
                                           rel="noreferrer"
                     >
                   <LocationOnIcon />
                   <MDBCardText className="small text-muted mb-0">{val.campusAddress}</MDBCardText>
                   <MDBCardText className="small text-muted mb-0">{val.campusAddress2}</MDBCardText>
                   </Link>
                 </div>
                 <div className="px-3">
                  
                 </div>
                 <div className="px-3">
                   <AccessTimeIcon />
                   <MDBCardText className="small text-muted mb-0">- {val.serviceTimes} -</MDBCardText>
                   <MDBCardText className="small text-muted mb-0">- {val.serviceTimes2} -</MDBCardText>
                 </div>
               </div>
             </div>
             <MDBCardBody className="text-black p-4">
               <div className="mb-5">
               <div style={{display: 'flex', flexDirection: 'row'}}>
                 <p className="lead fw-normal mb-1" style={{fontWeight: 'bold'}}>Welcome Home!</p>
                 
                 </div>
                 <div className="p-4" style={{ backgroundColor: 'white' }}>
                   <MDBCardText className="font-italic mb-1" style={{color: 'black	'}}>{val.campusAbout}</MDBCardText>
                 </div>
               </div>
               <div className="mb-5">
               <div style={{display: 'flex', flexDirection: 'row'}}>
                 <p className="lead fw-normal mb-1" style={{fontWeight: 'bold'}}>Follow our Socials</p>
                 
                 </div>
                 <div className="p-4" style={{ backgroundColor: '#f8f9fa' }}>
                   <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                     <Link style={{
                         textDecoration: 'none',
                         color: 'inherit'
                       }}
                       to="https://www.facebook.com/ncfnorth"
                       target="_blank"
                       rel="noreferrer">
                     <FacebookIcon fontSize='large'/>
                     </Link>
                     <Link
                     style={{
                       textDecoration: 'none',
                       color: 'inherit'
                     }}
                     to="https://www.instagram.com/ncfnorth/"
                     target="_blank"
                     rel="noreferrer">
                     <InstagramIcon fontSize='large'/>
                     </Link>
                     <Link
                                           style={{
                                             textDecoration: 'none',
                                             color: 'inherit'
                                           }}
                                           to="https://www.ncfnorth.co.za"
                                           target="_blank"
                                           rel="noreferrer"
                     >
                     <LanguageIcon fontSize='large'/>
                     </Link>
                   </div>
                 </div>
               </div>
               <div className="d-flex justify-content-between align-items-center mb-4">
          
               </div>
               <MDBRow>
               
               </MDBRow>
             </MDBCardBody>
           </MDBCard>
         </MDBCol>
       </MDBRow>
       </MDBContainer>
       ))}
     </div>
      )
    }
  return (
   
  <div>
   {detailsLoader? (<Skeleton variant="card" width={"100%"} height={'100vh'} style={{borderRadius: 25, marginTop: 100}} />) : (<ReturnDetailsData/>)}
  </div>
  )
}

export default Details
