import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB-UXn27Q94nMK8k0QyNdiOY5ZKJE4b2Tw",
  authDomain: "ncfn-notifications.firebaseapp.com",
  projectId: "ncfn-notifications",
  storageBucket: "ncfn-notifications.appspot.com",
  messagingSenderId: "767056812370",
  appId: "1:767056812370:web:29c05d5e0a435eaba4828b",
  measurementId: "G-3HMCH76MKZ"
};
export const requestForToken = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BB6WKAgcDtC4PBATSWGdsFBV2dMB8TUdxyv5oKH_XhJ7bNQHjFdrxIjiUW74S9aoy7uCQRcNyee5oZsx0e2T_ho' });
        if (currentToken) {
            console.log('current token for client: ', currentToken);
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
  };

// Initialize Firebase
const FirebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(FirebaseApp);
const messaging = getMessaging();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });